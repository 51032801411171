@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
    scroll-behavior: smooth;
  }

body{
    /* background-color: #E2E8F0 !important; */
    font-family: Roboto;
}

.App{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px !important;
}
.App .navbar{
 border-bottom: 1px solid #E2E8F0 ;
}

.App .navbar-brand{
    color: #1D4ED8;
    font-size: 32px;
    font-weight: 700;
}

.Header-section{
    margin: -30px 0px -30px 0px;
}

.image-logo{
    width: 230px;
    height: auto;
}

.App .nav-link{
    color: #0F172A;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
}
.App .nav-link:hover{
    color: #1d58e4;
    transition: 0.5s;
}

.heroSec{
    padding: 48px 0 120px;
}

.heroSec h1{
    font-weight: 800;
    font-size: 72px;
    text-align: center;
    font-family: Roboto;
    margin:  0 0 24px;
}



.heroSec p{
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    margin:  0 0 24px;
    align-items: center;
    max-width: 755px;
    margin:  0 auto 47px;
}

.heroGif{
    border-radius: 24px;
    /* overflow: hidden; */
    position: relative;
    padding: 0 100px;
}

.heroGif img{
    width: 100%;
    z-index: 99;
    position: relative;
}

.heroGif:before{
    content:'';
    position: absolute;
    top: -100px;
    left: 9px;
    width: 176px;
    height: 176px;
    background: url('./assets/Icons.png');

}

.heroGif:after{
    content:'';
    position: absolute;
    bottom: -100px;
    right: 9px;
    width: 176px;
    height: 176px;
    background: url('./assets/Icons.png');
    transform: rotate(-180deg);
}

.serviceSec{
    background-color: #eff6ff;
    padding: 80px 0 80px;
}


.serviceSec h2{
    font-weight: 800;
    font-size: 72px;
    text-align: center;
    font-family: Roboto;
    margin:  0 0 24px;
}

.showImage{
    border-radius: 10px;
}

.container-terms{
    padding: 50px 200px 50px 200px;
}

.serviceSec p{
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    font-family: Roboto;
    margin:  0 0 24px;
    align-items: center;
    max-width: 755px;
    margin:  0 auto 47px;
}


.serviceCardText{
    padding: 24px;
    background-color: #fff;
    min-height: 230px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin:  0 0 24px;
}

.serviceCardText h3{
    font-size: 24px;
    font-weight: 700;
    margin: 0 0 24px;
}

.serviceCardText p{
    text-align: left;
    font-size: 18px;
    font-weight: 400;
    padding-right: 50px;
    margin: 0 0 24px;
}




.serviceCard{
    border-radius: 10px;
    overflow: hidden;
}

.serviceCardImg img{
    width: 100%;
}

.serviceCard a{
    font-size: #1D4ED8;
    font-size: 16px;
    text-decoration: none;

}

.aboutSec{
    padding: 80px 0 80px;
}

.aboutSec h2{
    font-weight: 800;
    font-size: 72px;
    font-family: Roboto;
    margin:  0 0 24px;
}



.aboutSec p{
    font-weight: 400;
    font-size: 18px;
    font-family: Roboto;
    margin:  0 0 24px;
    align-items: center;
    max-width: 755px;
    margin:  0 auto 47px;
}


.aboutBanner{
    padding: 0 0 0 100px;
    position: relative;
}

.aboutBanner img{
    position: relative;
    z-index: 999;
}

.aboutBanner:before{
    content:'';
    position: absolute;
    top: -50px;
    left: 9px;
    width: 176px;
    height: 176px;
    background: url('./assets/Icons.png');
}

.ourMission{
    padding: 80px;
    border-radius: 30px;
    color: #fff;
    background-image: url('./assets/b1af0bdf1dc2d88026e10f9836fb62a1.jpg');
    background-size: cover;
    background-position-y: -123px;
    position: relative;
    overflow: none;
}

.ourMission:before{
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #1D4ED8;
    opacity: 0.75;
    top: 0;
    left: 0;
    border-radius: 30px;
}



.ourMission h2{
    font-weight: 800;
    font-size: 72px;
    font-family: Roboto;
    margin:  0 0 24px;
    position: relative;
}


.ourMission p{
    font-weight: 400;
    font-size: 18px;
    font-family: Roboto;
    margin:  0 0 24px;
    max-width: 560px;
    margin:  0;
    position: relative;
}


.ourTeam h2{
    font-weight: 800;
    font-size: 72px;
    font-family: Roboto;
    margin:  0 0 24px;
    position: relative;
    text-align: center;
}


.ourTeam p{
    font-weight: 400;
    font-size: 18px;
    font-family: Roboto;
    margin:  0 0 24px;
    max-width: 560px;
    margin:  0 auto 48px;
    position: relative;
    text-align: center;

}

.ourTeam{
    padding: 80px 0;

}

.react-multi-carousel-list  {
    padding: 0 0 55px;
}

.react-multi-carousel-list p{
    text-align: left;
}


.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left{
    position: absolute;
    bottom: 0;
    right: 46px;
    left: auto;
}

.react-multi-carousel-track li:nth-child(odd){
    margin: 50px 0 0;
}

.ourApproach h2{
    font-weight: 800;
    font-size: 72px;
    font-family: Roboto;
    margin:  0 0 24px;
    position: relative;
    text-align: center;
}


.ourApproach p{
    font-weight: 400;
    font-size: 18px;
    font-family: Roboto;
    margin:  0 0 24px;
    max-width: 900px;
    margin:  0 auto 48px;
    position: relative;
    text-align: center;

}



.ourApproach {
    background-color: #eff6ff;
    padding: 80px 0 80px;
  }

.ourApprCard p{
    text-align: left;
    margin: 0 0 24px;
}


.contactSec{
    padding: 80px 0;

}


.contactSec h2{
    font-weight: 800;
    font-size: 72px;
    font-family: Roboto;
    margin:  0 0 24px;
    position: relative;
    text-align: center;
}


.contactSec p{
    font-weight: 400;
    font-size: 18px;
    font-family: Roboto;
    margin:  0 0 24px;
    margin:  0 auto 24px;
    position: relative;
    text-align: center;
}


.contactUsContainer{
    background: linear-gradient(99.45deg, #312E81 0%, #1E40AF 0.01%, #1E3A8A 46%, #701A75 96.97%);
    border-radius: 30px;
    color: #fff;
    padding: 80px;
    text-align: center;
}

.contactUsContainer button{
   background-color: #2563EB;
   border: 0;
   color: #fff;
   padding: 12px 24px 12px 24px;
   border-radius: 8px;
   font-weight: 700;
}


.Footer .navbar-brand{
    color: #475569;
    display: flex;
    align-items: center;
    gap: 24px;
}

.Footer .navbar-brand span{
   color: #1D4ED8;
}

/* Card section */

.container-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 100px;
  }
  
  .card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    max-width: 400px;
    text-align: center;
    font-family: arial;
    flex: 1 1 400px; /* Grow, shrink, and base width */
    border: 1px solid black;
  }
  
  .card div{
    margin: -20px 0px 25px -25px;
  }
  .title {
    color: grey;
    font-size: 18px;
  }
  
  a {
    text-decoration: none;
    font-size: 22px;
    color: black;
  }

.scroll-to-top{
    position: fixed; 
    bottom: 70px; 
    right: 20px; 
    padding: 10px 20px; 
    background-color: #007bff; 
    color: #fff; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer;
    z-index: 9999; 
}

.socialLink span{
    color: grey;
    font-size: 20px;
    margin-left: 10px;
    font-family: sans-serif;
}
.fab{
    margin-left: 20px;
    color: #475569;
    font-size: 30px;
}

.contactUsContainer button:hover{
    background-color: #0b76e9;    
}

.contactUsContainer button a {
    color: white;
    text-decoration: none;
}

.driver-popover.driverjs-theme {
  background-color: #fde047;
  color: white;
}

.driver-popover.driverjs-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.driverjs-theme .driver-popover-title,
.driver-popover.driverjs-theme .driver-popover-description,
.driver-popover.driverjs-theme .driver-popover-progress-text {
  color: #000;
  margin-right: 10px;
}

.driver-popover.driverjs-theme button {
  flex: 1;
  text-align: center;
  background-color: #000;
  color: #ffffff;
  border: 2px solid #000;
  text-shadow: none;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 6px;
}

.driver-popover.driverjs-theme button:hover {
  background-color: #000;
  color: #ffffff;
}

.driver-popover.driverjs-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover.driverjs-theme .driver-popover-close-btn {
  background-color: transparent;
  border: 0px;
  font-size: 25px;
  color: grey;
}

.driver-popover.driverjs-theme .driver-popover-close-btn:hover {
  color: #383737;
  background-color: transparent;
  border: 0px;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: #fde047;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: #fde047;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: #fde047;
}

.driver-popover.driverjs-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: #fde047;
}