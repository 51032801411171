@media screen and (max-width:  1024px) {
    .image-logo {
        width: 125px;
        height: auto;
    }
    .App .navbar-brand {
        color: #1D4ED8;
        font-size: 35px;
        font-weight: 700;
    }
    .nav-brand-footer{
        margin-left: 0px !important;
    }
    .ourMission{
        margin:  0px;
        padding: 45px;
    }
    .ourMission h2{
        font-size: 60px;
    }

    .contactUsContainer{
        padding: 45px;
    }

    .ourTeam{
        padding: 0px 0;
        margin-bottom: 50px;
    
    }

    .ourTeam h2{
        font-size: 60px;
    }

    .aboutSec{
        padding: 80px 0;
    }
    .aboutSec h2{
        text-align: center;
        font-size: 60px;
    }
    .aboutSec p{
        text-align: center !important;
    }

    .App .navbar-brand {
        text-align: left;
        margin-left: 0px;
    }
    .heroSec h1 {
        font-size: 60px !important;
    }
    .heroGif{
        border-radius: 24px;
        /* overflow: hidden; */
        position: relative;
        padding: 0 20px !important;
    }
    .aboutBanner{
        padding: 0 0 0 0px !important;
        position: relative;
    }
    .aboutBanner img{
        width: 100% !important;
    }
    .container-card {
        gap: 50px;
      }
    .card {
      flex: 1 1 100%; /* Full width on small screens */
    }
    .navbar-nav{
        display: flex !important;
        flex-direction: row !important;
    }
    .nav-item{
        flex: 1 !important ;
        font-size: 12px !important;
    }
    .nav-footer{
        margin-top: 20px;
        width: 350px;
        margin-left: 0px;
        text-align: right;
        align-items: flex-end;
        margin-left: -30px;
    } 

    .container-terms{
        padding: 50px 100px 50px 100px;
    }
    .Header-section{
        margin: -10px 0px -10px 0px !important;
    }
    .App .nav-link{
        color: #0F172A;
        font-weight: 500;
        font-size: 15px;
        cursor: pointer;
    }
}

@media screen and (max-width:  512px) {
    .image-logo {
        width: 125px;
        height: auto;
    }
    .App .navbar-brand {
        color: #1D4ED8;
        font-size: 45px;
        font-weight: 700;
    }
    .nav-brand-footer{
        margin-left: 0px !important;
    }
    .ourMission{
        margin: 0px !important;
        padding: 45px;
    }
    .ourMission h2{
        font-size: 60px;
    }

    .contactUsContainer{
        padding: 45px;
    }

    .ourTeam{
        padding: 0px 0;
        margin-bottom: 50px !important;
    
    }

    .ourTeam h2{
        font-size: 60px;
    }

    .aboutSec{
        padding: 80px 0;
    }
    .aboutSec h2{
        text-align: center;
        font-size: 60px;
    }
    .aboutSec p{
        text-align: center !important;
    }

    .App .navbar-brand {
        text-align: left;
        margin-left: -10px;
    }
    .heroSec h1 {
        font-size: 60px !important;
    }
    .heroGif{
        border-radius: 24px;
        /* overflow: hidden; */
        position: relative;
        padding: 0 20px !important;
    }
    .aboutBanner{
        padding: 0 0 0 0px !important;
        position: relative;
    }
    .aboutBanner img{
        width: 100% !important;
    }
    .container-card {
        gap: 50px;
      }
    .card {
      flex: 1 1 100%; /* Full width on small screens */
    }
    .navbar-nav{
        display: flex !important;
        flex-direction: row !important;
    }
    .nav-item{
        flex: 1 !important ;
        font-size: 10px !important;
    }
    .nav-footer{
        margin-top: 20px;
        width: 350px;
        margin-left: 0px;
        text-align: right;
        align-items: flex-end;
        margin-left: -30px;
    } 

    .container-terms{
        padding: 50px 40px 50px 40px;
    }
    /* App.css or a similar stylesheet */

.image-logo {
    margin-right: 10px; /* Space between logo and text */
  }
  
  .nav-link {
    padding: 0.5rem 1rem; /* Adjust padding for links */
  }
  .logo-title{
    margin-left: -20px;
  }
}
